import React, { useEffect, useState } from 'react';
import { Select, Tooltip, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit, actionPostPropertyEdit } from '../../../actions';
import { isGlobalFilter } from '../../helpers/helpers';
import { QuestionCircleFilled, LinkOutlined } from '@ant-design/icons';
import { getComponentData, getLayerData } from './helpers/helpers';
import { useLayerEffect, useRadiusEffect, useSymbolEffect } from './helpers/hooks';
const { Option } = Select;

export interface SelectStepsInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

export interface prevValueRefProps {
  value: string;
  layerField: string;
  colorScale: string;
}

const SelectStepsInput: React.FC<SelectStepsInputProps> = (props: SelectStepsInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, options, refreshKey, parents, selectMode, placeHolder, objectKey, tooltip, labelLink } =
    item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const firstParentValues =
    itemIndex !== undefined && (component.type === 'table' || component.type === 'chart' || component.type === 'map')
      ? component?.[parents[0]]?.[itemIndex]
      : {};
  const [value, setValue] = useState<string | number>('');
  const layer: Dictionary =
    parents?.length === 1 && itemIndex !== undefined ? component?.[parents[0]]?.[itemIndex] : {};

  useEffect(() => {
    let currentValue;
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      currentValue = component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      if (objectKey) {
        currentValue = component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (objectKey) {
        currentValue = component?.[parents[0]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (!parents) {
      setValue(component[property]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layer?.colorMethod,
    firstParentValues?.value,
    firstParentValues?.colorMode,
    parents,
    itemIndex,
    childIndex,
    componentIndex
  ]);
  const source = post.sources?.[layer?.source];

  const keyedLayerData = getLayerData(post, layer, firstParentValues);
  const keyedComponentData = getComponentData(post, component, firstParentValues);

  useLayerEffect({
    post,
    layer,
    component,
    value,
    firstParentValues,
    keyedLayerData,
    keyedComponentData,
    componentIndex,
    itemIndex,
    parents,
    source
  });

  useRadiusEffect({
    post,
    layer,
    component,
    value,
    firstParentValues,
    keyedLayerData,
    keyedComponentData,
    componentIndex,
    itemIndex,
    parents,
    source
  });

  useSymbolEffect({
    post,
    layer,
    component,
    value,
    firstParentValues,
    keyedLayerData,
    keyedComponentData,
    componentIndex,
    itemIndex,
    parents,
    source
  });

  return (
    <div className="layout-input">
      <Row gutter={10}>
        <Col sm={6}>
          <label>
            {label}
            &nbsp;
            {tooltip && (
              <Tooltip title={tooltip}>
                <QuestionCircleFilled />
              </Tooltip>
            )}
            {labelLink && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={labelLink} target="_blank">
                <LinkOutlined />
              </a>
            )}
          </label>
        </Col>
        <Col sm={18}>
          <Select
            placeholder={placeHolder ? placeHolder : undefined}
            disabled={isGlobalFilter(component, parents, childIndex, itemIndex)}
            value={value ? value : undefined}
            allowClear
            showSearch
            mode={selectMode}
            getPopupContainer={() => document.getElementById('drawer-content') as HTMLElement}
            onChange={(value) => {
              setValue(value);
              setTimeout(() => {
                if (item?.entity === 'post') {
                  dispatch(
                    actionPostPropertyEdit({
                      property: property,
                      value: value
                    })
                  );
                } else {
                  dispatch(
                    actionPostComponentSettingEdit({
                      parents: parents,
                      property: property,
                      componentIndex: componentIndex,
                      itemIndex: itemIndex,
                      childIndex: childIndex,
                      refreshKey: refreshKey,
                      objectKey: objectKey,
                      value: value
                    })
                  );
                }
              }, 500);
            }}
          >
            {options &&
              options.map((item: Dictionary, index: number) => (
                <Option key={index} value={item?.value}>
                  {item?.label}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export { SelectStepsInput };
