import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MapInstance from './components/MapInstance';
import { GenericComponent } from '../Component';
import './style.css';
import { Dictionary } from '@onaio/utils';
import { Legend } from './components/Legend';
import { LayerProps } from '../../../configs/types';

export interface OwnPropsFields {
  isEmbed: boolean;
  componentId: string;
  index: number;
}

export interface ComponentProps {
  id: string;
  key: string;
  height: number;
  layers: LayerProps[];
}

export interface MapComponentProps {
  componentId: string;
  index: number;
  component: ComponentProps;
  isEmbed: boolean;
}

const MapComponent: React.FC<MapComponentProps> = ({ index, isEmbed }) => {
  const [mapVisible] = useState(true);

  // Replace mapStateToProps with useSelector hooks to access Redux state
  const component = useSelector((state: Dictionary) => state.post.components[index]);
  const edit = useSelector((state: Dictionary) => state.post.edit);
  const config = useSelector((state: Dictionary) => state.config);
  const user = useSelector((state: Dictionary) => state.user);
  const postSources = useSelector((state: Dictionary) => state.post.sources);
  const space = useSelector((state: Dictionary) => state.space);
  const account = useSelector((state: Dictionary) => state.post.account_id);
  const sources = useSelector((state: Dictionary) => state.sources);

  let mapLegendAboveLayers: LayerProps[] = [];
  let mapLegendBelowLayers: LayerProps[] = [];

  if (component?.layers) {
    mapLegendAboveLayers = component.layers.filter(
      (layer: Dictionary) => layer.legend && layer.legend.position === 'above' && layer.legend.isEnabled
    );
    mapLegendBelowLayers = component.layers.filter(
      (layer: Dictionary) => layer.legend && layer.legend.position === 'below' && layer.legend.isEnabled
    );
  }

  const genericComponentProps = {
    componentIndex: index,
    isEmbed: isEmbed,
    drawerTitle: 'Map',
    componentId: component?.id
  };

  return (
    <GenericComponent {...genericComponentProps}>
      <div>
        {mapLegendAboveLayers.length > 0 && <Legend layers={mapLegendAboveLayers} />}
        <div
          className="map"
          style={{
            height: component?.height || 500
          }}
        >
          <MapInstance index={index} />
        </div>
        {mapLegendBelowLayers.length > 0 && <Legend layers={mapLegendBelowLayers} />}
      </div>
    </GenericComponent>
  );
};

export default MapComponent;
