import { useEffect, useState } from 'react';
import { Input, Button, Row, Col } from 'antd';
import { actionPostComponentInsert, actionConfigComponentIndexActive, actionConfigActiveLayerEdit } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';

export const Chat = () => {
  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const post = useSelector((store: any) => store.post);

  const handleSubmit = () => {
    setLoading(true);
    setPrompt('');
    axios
      .post(`${process.env.REACT_APP_CHAT_API}chat/message`, {
        message: prompt,
        sources: post.sources
      })
      .then((res) => {
        const msg = [...messages, res.data];
        setMessages(msg);
        if (res.data?.components?.length > 0) {
          res.data.components.forEach((item: any, index: number) => {
            dispatch(
              actionPostComponentInsert({
                index: post.components.length,
                value: item
              })
            );
            if (item.type === 'map') {
              dispatch(
                actionConfigComponentIndexActive({
                  value: post.components.length
                })
              );
              dispatch(
                actionConfigActiveLayerEdit({
                  value: 0
                })
              );
            }
          });
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="chat-ui">
      {loading && (
        <div className="chat-loader" style={{ fontSize: '50px' }}>
          <LoadingOutlined />
        </div>
      )}
      <div className="chat-ui-thread">
        <div className="content">
          <div className="chat-ui-message">
            <div className="chat-ui-message-author">Akuko</div>
            <p>Hello, welcome to Akuko! How can I help you today?</p>
          </div>
          {messages.map((item: any, index: number) => (
            <div key={index} className="chat-ui-message">
              <div className="chat-ui-message-author">Akuko</div>
              {item.message}
            </div>
          ))}
        </div>
      </div>
      <div className="chat-form">
        <div className="content">
          <Row gutter={0}>
            <Col xs={18}>
              <Input
                size="large"
                value={prompt}
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
                onPressEnter={() => {
                  handleSubmit();
                }}
              />
            </Col>
            <Col xs={3}>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                type="primary"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
