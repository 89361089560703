/**
 * Post reducer selectors
 *
 * When consuming the selectors inside components, it's necessary to make sure that each instance
 * of a component get its own selector instances for correct memoization
 *
 * e.g To consume selectorA, inside the component, create a selector factory
 *
 * const makeSelectorA = () => selectorA
 *
 * References:
 * (https://redux.js.org/usage/deriving-data-selectors)
 * (https://react-redux.js.org/api/hooks#using-memoizing-selectors)
 * (https://github.com/reduxjs/reselect#accessing-react-props-in-selectors)
 */
import { Store } from 'redux';
import { createSelector } from 'reselect';
import { Dictionary } from '@onaio/utils';
import { Source, GenericPostComponent, SourceQueryObject, MapLayer, MapComponent } from '../../configs/component-types';

/**
 * get post components
 *
 * @param {Partial<Store>} state redux store
 * @returns {PostComponent[]} an array of components
 */
export const componentsSelector = (
  state: Partial<Store>
): GenericPostComponent[] => {
  return (state as Dictionary).post.components;
};

/**
 * get post sources
 *
 * @param {Partial<Store>} state redux store
 * @returns {Dictionary<Source>}
 */
export const sourcesSelector = (state: Partial<Store>): Dictionary<Source> => {
  return (state as Dictionary).post.sources;
};

/**
 * get post data
 *
 * @param {Partial<Store>} state redux store
 * @returns {Dictionary} post data
 */
export const dataSelector = (state: Partial<Store>): Dictionary => {
  return (state as Dictionary).post.data;
};

/**
 * get post account id
 *
 * @param {Partial<Store>} state redux store
 * @returns {string} account ID
 */
export const accountIDSelector = (state: Partial<Store>): string => {
  return (state as Dictionary).post.account_id;
};

/**
 * get post source query history
 *
 * @param {Partial<Store>} state redux store
 * @returns {Dictionary} post data
 */
export const sourceQueryHistorySelector = (
  state: Partial<Store>
): Dictionary => {
  return (state as Dictionary).post.sourceQueryHistory;
};

/**
 * get user editing post
 *
 * @param {Partial<Store>} state redux store
 * @returns {string} id of the user editing the post
 */
export const userEditingPostSelector = (state: Partial<Store>): string => {
  return (state as Dictionary).post.edit;
};

/**
 * post is public
 *
 * @param {Partial<Store>} state redux store
 * @returns {string} id of the user editing the post
 */
export const postIsPublic = (state: Partial<Store>): boolean => {
  return (state as Dictionary).post.public;
};

export interface Filters {
  componentId?: string; // id of the post component
  filterIndex?: number; // index of the component filter
  primaryLayerIndex?: number; // index of the map primary layer
  childLayerIndex?: number; // index of the map child layer
}

export interface ComponentProps {
  componentIndex: number; // id of the post component
  filterIndex?: number; // index of the component filter
  primaryLayerIndex?: number; // index of the map primary layer
  childLayerIndex?: number; // index of the map child layer
}

/**
 * get component id from props
 *
 * @param {Partial<Store>} _ redux store
 * @param {Filters} props filters object
 * @returns {string} component id
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getComponentId = (_: Partial<Store>, props: Filters) =>
  props.componentId;

/**
 * get component index from props
 *
 * @param {Partial<Store>} _ redux store
 * @param {Filters} props filters object
 * @returns {number} component index
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getComponentIndex = (_: Partial<Store>, props: ComponentProps) =>
  props.componentIndex;

/**
 * get filter index from props
 *
 * @param {Partial<Store>} _ redux store
 * @param {Filters} props filters object
 * @returns {number} filter index
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFilterIndex = (_: Partial<Store>, props: Filters) =>
  props.filterIndex;

/**
 * get map primary layer index from props
 *
 * @param {Partial<Store>} _ redux store
 * @param {Filters} props filters object
 * @returns {number} index
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getMapPrimaryLayerIndex = (_: Partial<Store>, props: Filters) => props.primaryLayerIndex;

/**
 * get map child layer index from props
 *
 * @param {Partial<Store>} _ redux store
 * @param {Filters} props filters object
 * @returns {number} index
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getMapChildLayerIndex = (_: Partial<Store>, props: Filters) =>
  props.childLayerIndex;

/**
 * get one component using its id
 */
export const getComponentById = createSelector(
  [componentsSelector, getComponentId],
  (components, componentId): GenericPostComponent | undefined => {
    return components.find((component) => component.id === componentId);
  }
);

/**
 * get one component using its index
 */
export const getComponentByIndex = createSelector(
  [componentsSelector, getComponentIndex],
  (components, componentIndex): GenericPostComponent | undefined => {
    return components[componentIndex];
  }
);

/**
 * get component source
 *
 */
export const getComponentSource = createSelector(
  [sourcesSelector, getComponentById],
  (sources, component): Source | undefined => {
    if (!component) return undefined;

    return sources?.[component?.source];
  }
);

/**
 * get component source
 *
 */
export const getComponentSourceByIndex = createSelector(
  [sourcesSelector, getComponentByIndex],
  (sources, component): Source | undefined => {
    if (!component) return undefined;

    return sources[component.source];
  }
);

/**
 * get component data
 */
export const getComponentData = createSelector(
  [dataSelector, getComponentById],
  (data, component): Dictionary[] | undefined => {
    if (!component) {
      return [];
    }

    return data?.[component.id];
  }
);

/**
 * get component source query history object
 */
export const getComponentSourceQueryHistoryObject = createSelector(
  [sourceQueryHistorySelector, getComponentId],
  (history, id): SourceQueryObject | undefined => {
    if (!id || !history) {
      return undefined;
    }
    return history[id];
  }
);

/**
 * get component filter data
 */
export const getComponentFilterData = createSelector(
  [dataSelector, getComponentById, getFilterIndex],
  (data, component, filterIndex): Dictionary[] | undefined => {
    if (!component || filterIndex === undefined) {
      return [];
    }

    return data?.[`${component.id}-filter-${filterIndex}`];
  }
);

/**
 * get map layer
 */
export const getMapLayerByIndex = createSelector(
  [getComponentById, getMapPrimaryLayerIndex, getMapChildLayerIndex],
  (component, primaryLayerIndex, childLayerIndex): MapLayer | undefined => {
    if (!component || primaryLayerIndex === undefined) return undefined;
    const map = component as MapComponent;
    const primaryLayer = map.layers[primaryLayerIndex];

    if (childLayerIndex !== undefined && primaryLayer.children) {
      return primaryLayer.children[childLayerIndex];
    }

    return primaryLayer;
  }
);

/** get map layer source */
export const getMapLayerSource = createSelector(
  [sourcesSelector, getMapLayerByIndex],
  (sources, layer): Source | undefined => {
    if (!layer) return undefined;

    return sources[layer.source];
  }
);

/**
 * get map layer
 */
export const getMapLayerComponentIndex = createSelector(
  [getComponentByIndex, getMapPrimaryLayerIndex, getMapChildLayerIndex],
  (component, primaryLayerIndex, childLayerIndex): MapLayer | undefined => {
    if (!component || primaryLayerIndex === undefined) return undefined;
    const map = component as MapComponent;
    const primaryLayer = map.layers[primaryLayerIndex];

    if (childLayerIndex !== undefined && primaryLayer.children) {
      return primaryLayer.children[childLayerIndex];
    }

    return primaryLayer;
  }
);

/** get map layer source */
export const getMapLayerSourceBasedOnComponentIndex = createSelector(
  [sourcesSelector, getMapLayerComponentIndex],
  (sources, layer): Source | undefined => {
    if (!layer) return undefined;

    return sources[layer.source];
  }
);
