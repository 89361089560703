import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import './style.css';
import { useSelector } from 'react-redux';
import { Table as AntdTable, ConfigProvider } from 'antd';
import { TableOutlined, LoadingOutlined } from '@ant-design/icons';
import { TABLE_DEFAULT_PAGINATION_SIZE, TABLE_DEFAULT_QUERY_LIMIT } from '../../../configs/constants';
import { TableComponent } from '../../../configs/component-types';
import { getComponentById, getComponentSource, userEditingPostSelector } from '../../../reducers/selectors/post';
import { Dictionary } from '@onaio/utils';
import { useFetchComponentData, usePagination } from '../hooks';
import { GenericComponent } from '../Component';
import { getTableColumns, getPivotColumns, getPivotData } from './helpers/helpers';
import { actionComponentTableSortEdit } from './actions';
import { useDispatch } from 'react-redux';
import { useResolvedStyles } from '../hooks';
import { userEmailSelector } from '../../../reducers/selectors/user';
import { activeComponentIndexSelector } from '../../../reducers/selectors/config';
import { getContextRow } from '../helpers';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

/** interface for component props */
export interface TableProps {
  componentIndex: number; // index of the table component
  componentId: string; // id of the table component
  ediTableSortActionCreator?: typeof actionComponentTableSortEdit;
  dataRow?: Dictionary;
}

/** default component props */
const defaultProps: Partial<TableProps> = {
  ediTableSortActionCreator: actionComponentTableSortEdit
};

const Table: React.FC<TableProps> = (props: TableProps) => {
  const dispatch = useDispatch();

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  const { componentIndex, componentId, ediTableSortActionCreator, dataRow } = props;

  const [isLoading, data] = useFetchComponentData(componentId, TABLE_DEFAULT_QUERY_LIMIT);
  const table = useSelector((state) => {
    /* @ts-ignore */
    const component = selectComponentById(state, { componentId });
    if (!component) {
      return undefined;
    }
    return component as TableComponent;
  });

  // get styles
  const styles = useResolvedStyles();

  const { filteredData } = getContextRow(dataRow, data, table, true);

  const { enablePagination, simple, pageSize, pageSizeOptions, onPageChange, onPageSizeChange } = usePagination(
    componentId,
    filteredData,
    TABLE_DEFAULT_PAGINATION_SIZE
  );

  // table pivot
  const [pivotTableData, setPivotTableData] = useState<Dictionary[]>([]);
  useEffect(() => {
    if (table?.pivotRows && table?.pivotColumns && table?.pivotValues) {
      const pivotData = getPivotData(table, filteredData);
      setPivotTableData(pivotData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(table), JSON.stringify(filteredData)]);

  /* @ts-ignore */
  const source = useSelector((state) => {
    /* @ts-ignore */
    const componentSource = selectComponentSource(state, { componentId });
    return componentSource;
  });
  const userEmail = useSelector(userEmailSelector);
  const userEditingPost = useSelector(userEditingPostSelector);
  const activeComponentIndex = useSelector(activeComponentIndexSelector);

  // standard table or pivot table data
  const getDataSource = () => {
    if (table && table.pivotRows && table.pivotColumns && table.pivotValues && pivotTableData) {
      return pivotTableData;
    } else {
      return filteredData;
    }
  };

  // standard table or pivot table columns
  const getColumns = () => {
    if (table && table.pivotRows && table.pivotColumns && table.pivotValues && pivotTableData) {
      return getPivotColumns(pivotTableData, table, source);
    } else {
      return getTableColumns(table, source, props.componentIndex);
    }
  };

  const handleOnChange = (page: number, pageSize?: number) => {
    if (page) {
      onPageChange(page);
    }

    if (pageSize) {
      onPageSizeChange(pageSize);
    }
  };

  const genericComponentProps = {
    componentIndex,
    componentId,
    drawerTitle: 'Table',
    dataRow: dataRow
  };
  const icon = <LoadingOutlined style={{ color: '#111', fontSize: 30 }} />;

  return (
    <GenericComponent {...genericComponentProps}>
      <div className="table">
        {!source ? (
          <div className="component-empty">
            <TableOutlined />
          </div>
        ) : (
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorBgContainer: table?.colorBgContainer || styles.colorBgContainer,
                  colorText: styles.colorText,
                  colorPrimary: table?.colorText || styles.colorText,
                  colorBorder: table?.borderColor || styles.tableBorderColor,
                  colorPrimaryHover: table?.colorText || styles.colorText,
                  colorTextDisabled: styles.colorText
                },
                Table: {
                  cellFontSize: table?.fontSize || styles.cellFontSize,
                  headerBg: table?.headerBg || styles.headerBg,
                  colorText: table?.colorText || styles.colorText,
                  colorPrimary: styles.colorText,
                  borderColor: table?.borderColor || styles.tableBorderColor,
                  headerColor: table?.headerColor || styles.headerColor,
                  headerSplitColor: styles.headerSplitColor,
                  headerSortActiveBg: table?.headerSortActiveBg || styles.headerSortActiveBg,
                  headerSortHoverBg: table?.headerSortHoverBg || styles.headerSortHoverBg,
                  colorBgContainer: table?.colorBgContainer || styles.colorBgContainer,
                  cellPaddingBlock: table?.cellPaddingBlock || styles.cellPaddingBlock
                }
              }
            }}
          >
            <AntdTable
              style={{
                whiteSpace: 'pre',
                color: table?.color,
                fontSize: `${table?.fontSize || 12}px`,
                fontFamily: table?.fontFamily || styles.tableFontFamily || 'Poppins'
              }}
              key={pageSize}
              rowKey="id"
              showHeader={table?.showHeader}
              bordered={table?.bordered}
              scroll={table?.fixedHeader ? { y: 240 } : undefined}
              className={`table-${componentIndex}`}
              loading={{ spinning: isLoading, indicator: icon }}
              columns={getColumns()}
              showSorterTooltip={false}
              pagination={
                enablePagination
                  ? {
                      pageSize,
                      simple,
                      pageSizeOptions,
                      hideOnSinglePage: true,
                      onChange: handleOnChange,
                      showSizeChanger: pageSizeOptions ? !!pageSizeOptions.length : false
                    }
                  : false
              }
              dataSource={getDataSource()}
              onChange={(_, __, sorter) => {
                if (
                  // if component is being edited save table sort
                  activeComponentIndex === componentIndex &&
                  userEmail === userEditingPost &&
                  ediTableSortActionCreator
                ) {
                  dispatch(
                    ediTableSortActionCreator({
                      componentIndex,
                      value: sorter
                    })
                  );
                }
              }}
            />
          </ConfigProvider>
        )}
      </div>
    </GenericComponent>
  );
};

Table.defaultProps = defaultProps;

export { Table };
