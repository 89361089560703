import { Dictionary } from '@onaio/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  classesChangeChecker,
  generateBreaks,
  generateCategories,
  generateRadiusBreaks,
  generateRadiusCategories,
  generateSymbolCategories,
  getCategoryOrBreakType,
  hasChanged,
  updateSymbolColors
} from './helpers';
import { fetchLayerData } from '../../../../Map/components/MapInstance/helpers';
import { actionComponentSourceQuery, actionPostComponentSettingEdit } from '../../../../actions';

export const useLayerEffect = ({
  post,
  layer,
  component,
  value,
  firstParentValues,
  keyedLayerData,
  keyedComponentData,
  componentIndex,
  itemIndex,
  parents,
  source
}: Dictionary) => {
  const dispatch = useDispatch();

  const prevValueRef = useRef(layer?.colorMethod); // value for map layer
  const prevColorModeRef = useRef(layer?.colorMode); // value for charts/tables
  const prevCategoricalClasses = useRef(layer?.categoricalClasses);

  const prevColorFieldRef = useRef(layer?.colorField);
  const prevSymbolFieldRef = useRef(layer?.symbolField);
  const prevFirstParentValueRef = useRef(firstParentValues?.value);
  const prevSymbolMethodRef = useRef(layer?.symbolMethod);
  const prevLayerDataRef = useRef(keyedLayerData);
  const prevComponentDataRef = useRef(keyedComponentData);
  const prevLayerIdRef = useRef(layer?.id);
  const prevComponentIdRef = useRef(component?.id);
  const isMountedRef = useRef(false); // Ref for isMounted

  useEffect(() => {
    const layerData = post.data?.[`layer-${layer?.id}`];
    const componentData = post.data?.[component?.id];

    const generateColors = (colorListingKey: string) => {
      return firstParentValues?.[`${colorListingKey}`]?.length && firstParentValues?.['colorRange'] === undefined
        ? firstParentValues?.[`${colorListingKey}`]
        : [];
    };

    // Check traversing through layers
    let sameLayer = true;

    if (layer?.id) {
      sameLayer = prevLayerIdRef.current === layer?.id;
    } else if (component?.id) {
      sameLayer = prevComponentIdRef.current === component?.id;
    }

    // Use the helper function to evaluate changes
    const valueChanged = hasChanged(layer?.colorMethod, prevValueRef.current);

    const colorModeChanged = hasChanged(layer?.colorMode, prevColorModeRef.current);

    const colorFieldChanged = hasChanged(layer?.colorField, prevColorFieldRef.current);
    const firstParentValueChanged = hasChanged(firstParentValues?.value, prevFirstParentValueRef.current);
    const layerDataChanged = hasChanged(keyedLayerData, prevLayerDataRef.current);
    const componentDataChanged = hasChanged(keyedComponentData, prevComponentDataRef.current);

    const breaksField = getCategoryOrBreakType(
      layer,
      value,
      component,
      layerData,
      keyedLayerData,
      keyedComponentData,
      componentData
    );

    const shouldGenerateSteps =
      valueChanged ||
      colorModeChanged ||
      colorFieldChanged ||
      firstParentValueChanged ||
      layerDataChanged ||
      componentDataChanged ||
      !(breaksField && layer?.[breaksField]?.length);

    if (layer && value && isMountedRef && shouldGenerateSteps && sameLayer) {
      if (
        ['breaks', 'generatedStepsBrakes', 'categorical', 'categories'].includes(value as string) &&
        (layer.colorField || layer?.value || layer.symbolField)
      ) {
        const configs = {
          componentIndex,
          itemIndex,
          parents,
          layerId: layer.id,
          componentId: component.id,
          colorField: layer.colorField || firstParentValues?.value,
          cube: source?.cube,
          classes: value === 'categorical' || value === 'categories' ? layer.categoricalClasses : layer.classes || 6,
          colorRange: layer.colorRange,
          colorScale: layer.colorScale,
          componentCube: component.cube,
          symbolField: layer.symbolField,
          iconColor: layer.iconColor
        };

        // Generate categories or breaks based on conditions
        if (layerData && layerData?.length > 0 && keyedLayerData !== undefined && layer.layerType !== 'symbol') {
          if (['categorical', 'categories'].includes(value as any)) {
            generateCategories(post, configs, dispatch, false, 'colorCategories', generateColors('colorCategories'));
          } else {
            generateBreaks(post, configs, dispatch, false, 'colorBreaks', generateColors('colorBreaks'));
          }
        } else if (
          component.type === 'table' &&
          componentData &&
          componentData.length > 0 &&
          keyedComponentData !== undefined
        ) {
          if (['categorical', 'categories'].includes(value as any)) {
            generateCategories(post, configs, dispatch, true, 'colorCategories', generateColors('colorCategories'));
          } else {
            generateBreaks(post, configs, dispatch, true, 'generatedSteps', generateColors('generatedSteps'));
          }
        } else if (
          component.type === 'chart' &&
          value === 'generatedStepsBrakes' &&
          componentData &&
          componentData.length > 0 &&
          keyedComponentData !== undefined
        ) {
          generateBreaks(post, configs, dispatch, true, 'ungroupedData', generateColors('ungroupedData'));
        } else if (!layerData?.length && !keyedLayerData) {
          fetchLayerData(component, layer, source, actionComponentSourceQuery, 50000, dispatch);
        }
      }
    }

    // Update refs after rendering

    prevValueRef.current = layer?.colorMethod;
    prevColorFieldRef.current = layer?.colorField;
    prevColorModeRef.current = layer?.colorMode;
    prevSymbolFieldRef.current = layer?.symbolField;
    prevSymbolMethodRef.current = layer?.symbolMethod;
    prevFirstParentValueRef.current = firstParentValues?.value;
    prevLayerDataRef.current = layerData?.[0]?.[`${layer.cube}.${layer.colorField}`];
    prevComponentDataRef.current = componentData?.[0]?.[`${component.cube}.${firstParentValues?.value}`];
    prevLayerIdRef.current = layer?.id;
    prevComponentIdRef.current = component?.id;
    isMountedRef.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, layer?.colorField, layer?.symbolField, firstParentValues?.value, keyedLayerData, keyedComponentData]);
};

export const useRadiusEffect = ({
  post,
  layer,
  component,
  value,
  keyedLayerData,
  componentIndex,
  itemIndex,
  parents,
  source
}: Dictionary) => {
  const dispatch = useDispatch();

  const isMountedRef = useRef(false);
  const prevValueRef = useRef(layer?.radiuseScale);
  const prevCircleRadiusFieldRef = useRef(layer?.circleRadiusField);
  const prevMinCircleRadiusRef = useRef(layer?.minCircleRadius);
  const prevMaxCircleRadiusRef = useRef(layer?.maxCircleRadius);
  const prevLayerDataRef = useRef(keyedLayerData);
  const prevCircleRadiusClassRef = useRef(layer?.circleRadiusClass);
  const prevCircleRadiusScaleRef = useRef(layer?.circleRadiusScale);
  const prevLayerIdRef = useRef(layer?.id);

  useEffect(() => {
    // Check traversing through layers
    let sameLayer = true;

    if (layer?.id) {
      sameLayer = prevLayerIdRef.current === layer?.id;
    }
    const layerData = post.data?.[`layer-${layer?.id}`];

    const valueChanged = hasChanged(layer?.radiusScale, prevValueRef.current);
    const circleRadiusFieldChanged = hasChanged(layer?.circleRadiusField, prevCircleRadiusFieldRef.current);
    const minCircleRadiusChanged = hasChanged(layer?.minCircleRadius, prevMinCircleRadiusRef.current);
    const maxCircleRadiusChanged = hasChanged(layer?.maxCircleRadius, prevMaxCircleRadiusRef.current);
    const circleRadiusClassChanged = hasChanged(layer?.circleRadiusClass, prevCircleRadiusClassRef.current);
    const circleRadiusScaleChanged = hasChanged(layer?.circleRadiusScale, prevCircleRadiusScaleRef.current);

    const keyedLayerDataChanged = hasChanged(keyedLayerData, prevLayerDataRef.current);

    // Combine the results to determine if steps should be generated
    const shouldGenerateSteps =
      valueChanged ||
      circleRadiusFieldChanged ||
      minCircleRadiusChanged ||
      maxCircleRadiusChanged ||
      circleRadiusClassChanged ||
      circleRadiusScaleChanged ||
      keyedLayerDataChanged ||
      !layer?.['radiusSteps']?.length;

    if (layer && value && isMountedRef.current && shouldGenerateSteps && sameLayer) {
      if (layer?.radiusScale === 'steps' && layer.circleRadiusField && layer.layerType === 'circle') {
        const configs = {
          componentIndex,
          itemIndex,
          parents,
          layerId: layer.id,
          componentId: component.id,
          cube: source?.cube,
          classes: layer.circleRadiusClass,
          radiusScale: layer.radiuseScale,
          componentCube: component.cube,
          radiusField: layer.circleRadiusField,
          circleRadiusScale: layer.circleRadiusScale,
          minCircleRadius: layer.minCircleRadius as number,
          maxCircleRadius: layer.maxCircleRadius as number,
          colorField: layer.colorField,
          colorRange: layer.colorRange,
          colorScale: layer.colorScale,
          circleRadiusClass: layer.circleRadiusClass
        };

        if (layerData && layerData?.length > 0 && keyedLayerData !== undefined) {
          generateRadiusBreaks(post, configs, dispatch, 'radiusSteps');
        }
      } else if (!layerData?.length && !keyedLayerData) {
        // get layer data to post.data
        fetchLayerData(component, layer, source, actionComponentSourceQuery, 50000, dispatch);
      }
    }

    // Update previous values in refs
    prevValueRef.current = layer?.radiusScale;
    prevCircleRadiusFieldRef.current = layer?.circleRadiusField;
    prevMinCircleRadiusRef.current = layer?.minCircleRadius;
    prevMaxCircleRadiusRef.current = layer?.maxCircleRadius;
    prevCircleRadiusClassRef.current = layer?.circleRadiusClass;
    prevLayerDataRef.current = layerData?.[0]?.[`${layer.cube}.${layer.circleRadiusField}`];
    prevCircleRadiusScaleRef.current = layer?.circleRadiusScale;
    prevLayerIdRef.current = layer?.id;
    isMountedRef.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value,
    layer?.circleRadiusField,
    layer?.minCircleRadius,
    layer?.maxCircleRadius,
    layer?.circleRadiusClass,
    layer?.circleRadiusScale,
    keyedLayerData
  ]);
};

export const useSymbolEffect = ({
  post,
  layer,
  component,
  firstParentValues,
  keyedLayerData,
  componentIndex,
  itemIndex,
  parents,
  source
}: Dictionary) => {
  const dispatch = useDispatch();
  const prevCategoricalClasses = useRef(layer?.categoricalClasses);
  const prevSymbolFieldRef = useRef(layer?.symbolField);
  const prevSymbolMethodRef = useRef(layer?.symbolMethod);
  const prevIconColorRef = useRef(layer?.iconColor);
  const prevLayerDataRef = useRef(keyedLayerData);
  const prevLayerIdRef = useRef(layer?.id);
  const isMountedRef = useRef(false); // Ref for isMounted

  useEffect(() => {
    const layerData = post.data?.[`layer-${layer?.id}`];

    if (layer?.regenerateBreaks === false) {
      dispatch(
        actionPostComponentSettingEdit({
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          parents: parents,
          property: 'regenerateBreaks',
          value: true
        })
      );
      // Update refs after rendering

      prevSymbolFieldRef.current = layer?.symbolField;
      prevSymbolMethodRef.current = layer?.symbolMethod;
      prevIconColorRef.current = layer?.iconColor;
      prevCategoricalClasses.current = layer?.categoricalClasses;
      prevLayerDataRef.current = layerData?.[0]?.[`${layer.cube}.${layer.colorField}`];
      prevLayerIdRef.current = layer?.id;
      isMountedRef.current = true;
      return;
    }

    // Check traversing through layers
    let sameLayer = true;

    if (layer?.id) {
      sameLayer = prevLayerIdRef.current === layer?.id;
    }

    // Use the helper function to evaluate changes

    const symbolFieldChanged = hasChanged(layer?.symbolField, prevSymbolFieldRef.current);

    const layerDataChanged = hasChanged(keyedLayerData, prevLayerDataRef.current);

    const symbolMethodChanged = hasChanged(layer?.symbolMethod, prevSymbolMethodRef.current);

    const symbolClassChanged = classesChangeChecker(layer?.categoricalClasses, prevCategoricalClasses.current);

    const iconColorChanged = hasChanged(layer?.iconColor, prevIconColorRef.current);

    const shouldGenerateSteps =
      symbolFieldChanged ||
      layerDataChanged ||
      symbolMethodChanged ||
      symbolClassChanged ||
      !layer?.['symbolCategories']?.length;

    if (layer && isMountedRef && shouldGenerateSteps && sameLayer) {
      if (layer.symbolField) {
        const configs = {
          componentIndex,
          itemIndex,
          parents,
          layerId: layer.id,
          componentId: component.id,
          colorField: layer.colorField || firstParentValues?.value,
          cube: source?.cube,
          classes: layer.categoricalClasses,
          componentCube: component.cube,
          symbolField: layer.symbolField,
          iconColor: layer.iconColor,
          colorRange: layer.colorRange,
          colorScale: layer.colorScale
        };

        // Generate categories or breaks based on conditions
        if (layer.layerType === 'symbol' && layer?.symbolMethod === 'categorical') {
          generateSymbolCategories(post, configs, dispatch, false, 'symbolCategories', []);
        } else if (!layerData?.length && !keyedLayerData) {
          fetchLayerData(component, layer, source, actionComponentSourceQuery, 50000, dispatch);
        }
      }
    } else if (iconColorChanged && layer?.['symbolCategories']?.length && sameLayer) {
      const configs = {
        componentIndex,
        itemIndex,
        parents,
        layerId: layer.id,
        componentId: component.id,
        colorField: layer.colorField || firstParentValues?.value,
        cube: source?.cube,
        classes: layer.categoricalClasses,
        componentCube: component.cube,
        symbolField: layer.symbolField,
        iconColor: layer.iconColor,
        colorRange: layer.colorRange,
        colorScale: layer.colorScale
      };
      updateSymbolColors(layer?.['symbolCategories'], configs, dispatch, 'symbolCategories');
    }

    // Update refs after rendering

    prevSymbolFieldRef.current = layer?.symbolField;
    prevSymbolMethodRef.current = layer?.symbolMethod;
    prevIconColorRef.current = layer?.iconColor;
    prevCategoricalClasses.current = layer?.categoricalClasses;
    prevLayerDataRef.current = layerData?.[0]?.[`${layer.cube}.${layer.colorField}`];
    prevLayerIdRef.current = layer?.id;
    isMountedRef.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer?.categoricalClasses, layer?.symbolField, layer?.symbolMethod, layer?.iconColor, keyedLayerData]);
};
